import React, { useState, useEffect } from "react";

const Creatives = ({
  campaignID,
  setCreativeName,
  creativeName,
  creativeID,
  setCreativeID,
  sethasRecipe,
  fw_counter,
  setfw_counter,
  setToggleState,
  reset_BL_Recipe,
  currentSelect,
  setCurrentSelect,
  setgenVAST,
}) => {
  const [posts, setPosts] = useState([]);
  const [isCreativeChanged, setisCreativeChanged] = useState(false);
  const CreativesUrl = `${process.env.REACT_APP_API_BASE_ROUTE}creatives/campaign/${campaignID}`;

  useEffect(() => {
    if (
      sessionStorage.getItem("isAuth") === "true" ||
      isCreativeChanged === true
    ) {
      let req = new Request(CreativesUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
        },
        mode: "cors",
      });

      if (campaignID !== "") {
        fetch(req)
          .then((res) => {
            return res.json();
          })
          .then((jsonData) => {
            // sethasRecipe(false);
            if (jsonData.length > 0) {
              setPosts(jsonData);
            } else {
              setPosts([]);
            }
          });
      }
    }
  }, [CreativesUrl, campaignID, creativeID, isCreativeChanged, sethasRecipe]);

  useEffect(() => {
    const isActive = document.getElementById("creativeSelect").classList.contains("active");
    if (isActive && currentSelect !== "creativeSelect") {
      document.getElementById("creativeSelect").classList.remove("active");
    }

  }, [currentSelect]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newCreative = { creativeName };

    const response = await fetch(CreativesUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
      },
      body: JSON.stringify(newCreative),
    });

    const data = await response.json();
    sethasRecipe(false);
    setCreativeName(data.name);
    setCreativeID(data.id);
    setisCreativeChanged(true);
    setfw_counter(3);
    reset_BL_Recipe();
  };

  const openFrame = () => {
    document
      .querySelector('.addNewForm[data-form="Creative"]')
      .classList.add("show");
  };

  const closeFrame = () => {
    document
      .querySelector('.addNewForm[data-form="Creative"]')
      .classList.remove("show");
  };

  const showDDM = () => {

    if(document.getElementById("creativeSelect").children.length === 0){
      document.getElementById("creativeSelect").classList.remove("active");
      openFrame();
    } else{
      
      if (!document.getElementById("creativeSelect").classList.contains("active")) {
        setCurrentSelect("creativeSelect");
        // Check the current creative if it's not selected
        const currentCreative =  document.getElementById(`creative-${creativeID}`);
        if (currentCreative && !currentCreative.checked) {
          currentCreative.checked = true;
        }
      }
      document.getElementById("creativeSelect").classList.toggle("active");

    }
  };

  const hideDDM = (e) => {
    setCreativeName(e.target.value);
    const getCretiveId = e.target.id;
    const gci = getCretiveId.replace("creative-", "");
    setCreativeID(gci);
    const getBoolean = () => {
      if (e.target.dataset.recipe === "true") {
        return true;
      } else {
        reset_BL_Recipe();
        return false;
      }
    };
    sethasRecipe(getBoolean());
    document.getElementById("creativeSelect").classList.remove("active");
    if(getCretiveId !== 'creative-NA'){
      setfw_counter(3);
    }
    setToggleState("upload");
    setgenVAST("");
  };

  const fixName = (el) => {
    const newName = el.replace(/[ `!@#$ %^&*()_+\-=[\]{};':"\\|,.<>/? ~]/g, '_');
    setCreativeName(newName)
  }

  return (
    <div className={`colw-100 valField ${fw_counter > 1 ? "" : "disabled"}`}>
      <h4 className="mb-5">
        <strong>Creatives</strong>
      </h4>
      <div className="flex">
        <div className="fg-3">
          <div className="rel-sect">
            <span className="selectlike" onClick={showDDM}>
              {creativeName === "" ? "Choose a Creative" : creativeName}
            </span>
            <div className="ddm" id="creativeSelect">
              {posts.map((post) => (
                <div key={post.id}>
                  <input
                    type="radio"
                    name="creativeSelect"
                    value={post.name}
                    id={`creative-${post.id}`}
                    data-recipe={post.hasRecipe}
                    onClick={hideDDM}
                  />
                  <label
                    htmlFor={`creative-${post.id}`}
                    className={post.hasRecipe ? "hasRecipe" : ""}
                  >
                    {post.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pl-15">
          <div className="addNew" onClick={openFrame}>
            +
          </div>
          <div className="addNewForm" data-form="Creative">
            <div className="rel-sect">
              <div className="closeForm" onClick={closeFrame}></div>
              <form onSubmit={handleSubmit}>
                <div className="row" data-spacing="5">
                  <div className="colw-100">
                    <h5>Add a new Creative</h5>
                  </div>
                  <div className="colw-100">
                    <input
                      type="text"
                      id="addCreative"
                      placeholder="Creative Name"
                      className="br-5"
                      onChange={(e) => fixName(e.target.value)}
                    />
                  </div>
                  <div className="colw-100">
                    <input
                      type="submit"
                      value="submit"
                      className="br-5"
                      onClick={closeFrame}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Creatives;
