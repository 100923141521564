import React from "react";
import AssetsForm from "./tabsContent/AssetsForm";
import TrafficTab from "./tabsContent/TrafficTab";
import ResourcesTab from "./tabsContent/ResourcesTab";

const TabNav = ({
  apiBaseUrl,
  brandID,
  campaignID,
  creativeID,
  brandName,
  campaignName,
  creativeName,
  setnotifyMsg,
  partnerName,
  partnerID,
  getCreative,
  setgetCreative,
  toggleState,
  setgetpreviewVideo,
  genVAST,
  setgenVAST,
  setVideoJobID,
  setprevAd,
  recipeID,
  setrecipeID,
  setfetchingRecipe,
  hasRecipe,
  sethasRecipe,
  isloadAD,
  setisloadAD,
  fw_counter,
  setfw_counter,
  spotvid,
  setspotvid,
  bground,
  setbground,
  fground,
  setfground,
  xpos,
  setxpos,
  ypos,
  setypos,
  svwidth,
  setsvwidth,
  videoSize,
  setvideoSize,
  assetPackage,
  setassetPackage,
  formState,
  setFormState,
  prevVid,
  setPrevVid,
  prevbg,
  setPrevbg,
  prevfg,
  setPrevfg,
  assetsArray,
  setAssetsArray,
  uploadedAssetArr,
  setuploadedAssetArr,
  assetsLoading,
  setassetsLoading,
  adGenerating,
  setadGenerating,
  assetsObj,
  setassetsObj,
  fillRecipe,
  setfillRecipe,
  reset_BL_Recipe,
}) => {
  return (
    <div className="tabcontent">
      <AssetsForm
        apiBaseUrl={apiBaseUrl}
        brandName={brandName}
        campaignName={campaignName}
        creativeName={creativeName}
        setnotifyMsg={setnotifyMsg}
        brandID={brandID}
        campaignID={campaignID}
        creativeID={creativeID}
        partnerName={partnerName}
        partnerID={partnerID}
        getCreative={getCreative}
        toggleState={toggleState}
        setgetCreative={setgetCreative}
        setgetpreviewVideo={setgetpreviewVideo}
        setgenVAST={setgenVAST}
        setVideoJobID={setVideoJobID}
        setprevAd={setprevAd}
        setrecipeID={setrecipeID}
        setfetchingRecipe={setfetchingRecipe}
        hasRecipe={hasRecipe}
        sethasRecipe={sethasRecipe}
        isloadAD={isloadAD}
        setisloadAD={setisloadAD}
        fw_counter={fw_counter}
        setfw_counter={setfw_counter}
        spotvid={spotvid}
        setspotvid={setspotvid}
        bground={bground}
        setbground={setbground}
        fground={fground}
        setfground={setfground}
        xpos={xpos}
        setxpos={setxpos}
        ypos={ypos}
        setypos={setypos}
        svwidth={svwidth}
        setsvwidth={setsvwidth}
        videoSize={videoSize}
        setvideoSize={setvideoSize}
        assetPackage={assetPackage}
        setassetPackage={setassetPackage}
        formState={formState}
        setFormState={setFormState}
        prevVid={prevVid}
        setPrevVid={setPrevVid}
        prevbg={prevbg}
        setPrevbg={setPrevbg}
        prevfg={prevfg}
        setPrevfg={setPrevfg}
        assetsArray={assetsArray}
        setAssetsArray={setAssetsArray}
        uploadedAssetArr={uploadedAssetArr}
        setuploadedAssetArr={setuploadedAssetArr}
        assetsLoading={assetsLoading}
        setassetsLoading={setassetsLoading}
        adGenerating={adGenerating}
        setadGenerating={setadGenerating}
        assetsObj={assetsObj}
        setassetsObj={setassetsObj}
        fillRecipe={fillRecipe}
        setfillRecipe={setfillRecipe}
        reset_BL_Recipe={reset_BL_Recipe}
      />
      <TrafficTab
        apiBaseUrl={apiBaseUrl}
        recipeID={recipeID}
        toggleState={toggleState}
        setgenVAST={setgenVAST}
        genVAST={genVAST}
        setnotifyMsg={setnotifyMsg}
      />
      <ResourcesTab toggleState={toggleState} />
    </div>
  );
};

export default TabNav;
