import React, { useState, useEffect } from "react";

const Brands = ({
  setBrandID,
  brandName,
  setBrandName,
  setCampaignName,
  setCreativeName,
  fw_counter,
  setfw_counter,
  setToggleState,
  reset_BL_Recipe,
  currentSelect,
  setCurrentSelect,
  setgenVAST,
}) => {
  const [posts, setPosts] = useState([]);
  const [isBrandChanged, setisisBrandChanged] = useState(false);
  const [addBrandName, setaddBrandName] = useState("");
  const BrandsUrl = process.env.REACT_APP_API_BASE_ROUTE + "brands";

  useEffect(() => {
    let isMounted = true;

    if (sessionStorage.getItem("isAuth") === "true") {
      let req = new Request(BrandsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
        },
        mode: "cors",
      });

      fetch(req)
        .then((res) => {
          return res.json();
        })
        .then((jsonData) => {
          if (jsonData.length === 0) {
            setPosts([{ id: "NA", name: "" }]);
          } else {
            if (isMounted) setPosts(jsonData);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [addBrandName, isBrandChanged, BrandsUrl]);

  useEffect(() => {
    const isActive = document.getElementById("brandSelect").classList.contains("active");
    if (isActive && currentSelect !== "brandSelect") {
      document.getElementById("brandSelect").classList.remove("active");
    }

  }, [currentSelect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(BrandsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
      },
      body: JSON.stringify({ brandName: addBrandName }),
    })
      .then((res) => res.json())
      .then((res) => {
        setBrandName(res.name);
        setBrandID(res.id);
        setisisBrandChanged(true);
        setfw_counter(1);
        setTimeout(() => {
          document.getElementById(res.id).checked = true;
        }, 500);
      });
  };

  const openFrame = () => {
    document
      .querySelector('.addNewForm[data-form="brand"]')
      .classList.add("show");
  };

  const closeFrame = () => {
    document
      .querySelector('.addNewForm[data-form="brand"]')
      .classList.remove("show");
  };

  const showDDM = () => {
    if (!document.getElementById("brandSelect").classList.contains("active")) {
      setCurrentSelect("brandSelect");
    }
    document.getElementById("brandSelect").classList.toggle("active");
  };

  const hideDDM = (e) => {
    setBrandName(e.target.value);
    document.getElementById("brandSelect").classList.remove("active");
    setBrandID(e.target.id);
    setCampaignName("Choose a Campaign");
    setCreativeName("Choose a Creative");
    setfw_counter(1);
    setToggleState("upload")
    reset_BL_Recipe();
    setgenVAST("")
  };

  const fixName = (el) => {
    const newName = el.replace(/[ `!@#$ %^&*()_+\-=[\]{};':"\\|,.<>/? ~]/g, '_');
    setaddBrandName(newName)
  }
  return (
    <div className="colw-100">
      <h4 className="mb-5">
        <strong>Brand</strong>
      </h4>
      <div className="flex">
        <div className="fg-3">
          <div className="rel-sect">
            <span className="selectlike" onClick={showDDM}>
              {brandName === "" ? "Choose a  Brand" : brandName}
            </span>
            <div className="ddm" id="brandSelect">
              {posts.map((post) => (
                <div key={post.id}>
                  <input
                    type="radio"
                    name="brandSelect"
                    value={post.name}
                    id={post.id}
                    onClick={hideDDM}
                  />
                  <label htmlFor={post.id}>{post.name}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pl-15">
          <div className="addNew" onClick={openFrame}>
            +
          </div>
          <div className="addNewForm" data-form="brand">
            <div className="rel-sect">
              <div className="closeForm" onClick={closeFrame}></div>
              <form onSubmit={handleSubmit}>
                <div className="row" data-spacing="5">
                  <div className="colw-100">
                    <h5>Add a new Brand</h5>
                  </div>
                  <div className="colw-100">
                    <input
                      type="text"
                      id="addBrand"
                      placeholder="Brand Name"
                      className="br-5"
                      onChange={(e) => fixName(e.target.value)}
                    />
                  </div>
                  <div className="colw-100">
                    <input
                      type="submit"
                      value="submit"
                      className="br-5"
                      onClick={closeFrame}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
