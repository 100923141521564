import React, { useState, useEffect } from "react";
import VideoLoading from "../components/VideoLoading";

const PreviewAD = ({
  toggleState,
  getCreative,
  getpreviewVideo,
  BL_videoStatus,
  setBL_videoStatus,
  videoJobID,
  videoProgress,
  setvideoProgress,
  setgetpreviewVideo,
  prevAd,
  setprevAd,
  setToggleState,
  fetchingRecipe,
  isloadAD,
  setisloadAD,
  hasRecipe,
  recipeID,
  setnotifyMsg,
  reset_BL_Recipe,
}) => {
  const [BL_videoURL, setBL_videoURL] = useState("");
  useEffect(() => {
    if (BL_videoStatus === "UploadSuccessful" && isloadAD && hasRecipe) {
      console.log("CREATIVE LINK: ", getCreative);
      console.log(getpreviewVideo);

      setBL_videoURL(getpreviewVideo);

      const v = document.getElementById("player");

      v.load();
      if (prevAd) {
        v.play();
      }

      window.BL.on_deviceInfo = function () {
        var deviceInfo = {
          configId: "1018",
          os: window.navigator.platform,
          osVersion: window.navigator.appVersion,
          appSessionID: "YOU-GENERATE-THIS-GUID",
          adSessionID: "YOU-GENERATE-THIS-ID-GUID",
          applicationName: "THIS IS YOUR APP NAME",
          deviceUUID: null,
          platformName:
            window.navigator.appCodeName + ", " + window.navigator.appName,
          mobileCarrier: "NA",
          manufacturer: window.navigator.vendor,
          advertisingIdentifier: null,
          applicationIdentifier: window.document.location.hostname,
          applicationVersion: "YOU-POPULATE-THIS",
          sdkVersion: "2.1.0",
          deviceModel: window.navigator.appVersion,
          deviceName: "desktop",
          screenResolution: window.width + "," + window.height,
          deviceConnectionType: window.navigator.connection,
          trackFlag: window.navigator.doNotTrack,
        };
        return deviceInfo;
      };
      window.BL.on_BL_opened = function () {
        // pub does something here
        console.log("BRIGHTLINE SDK: BL.openAd() was called");
      };
      window.BL.on_BL_rendered = function () {
        console.log("BRIGHTLINE SDK: creative has rendered");
      };
      window.BL.on_BL_expanded = function () {
        v.pause();
        console.log("BRIGHTLINE SDK: overlay has expanded, pause the stream");
      };
      window.BL.on_BL_collapsed = function () {
        v.play();
        console.log("BRIGHTLINE SDK: overlay has collapsed, resume the stream");
      };
      window.BL.on_BL_closed = function () {
        console.log("BRIGHTLINE SDK: BL.closeAd() was called");
      };

      window.BL.init();

      async function asyncCallBLAd() {
        console.log("calling");
        const result = await window.BL.init();
        if (result) {
          window.BL.openAd(getCreative, "player");
        }
      }
      asyncCallBLAd();
    }
  }, [BL_videoStatus, getCreative, getpreviewVideo, prevAd, isloadAD, hasRecipe]);

  const deleteVid = `${process.env.REACT_APP_API_BASE_ROUTE}video/${videoJobID}`;
  const deleteRecipe = `${process.env.REACT_APP_API_BASE_ROUTE}ad/recipe/${recipeID}`;

  const deleteMyVid = (e) => {
    let req = new Request(deleteVid, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
      },
      mode: "cors",
    });

    let request = new Request(deleteRecipe, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
      },
      mode: "cors",
    });

    document.querySelector(".previewTab").classList.remove("show");

    fetch(req)
      .then((res) => res.text()) // or res.json()
      .then((res) => {
        setprevAd(false);
      })
      .catch((err) => {
        console.error(err);
        setnotifyMsg({
          class: "error",
          msg: err.msg,
          delay: 5000,
        });
      });;

    fetch(request)
      .then((res) => res.text()) // or res.json()
      .then((res) => {
        setprevAd(false);
        setTimeout(() => {
          reset_BL_Recipe()
        }, 1500);
      });
  };

  const elem = document.getElementById("player_holder");
  const goFullScreen = () => {
    if (elem.requestFullscreen) {
      elem.classList.add("fullscreen");
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
      elem.classList.add("fullscreen");
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
      elem.classList.add("fullscreen");
    } else {
      elem.classList.remove("fullscreen");
    }
  };

  const checkVast = () => {
    closeAd();
    setToggleState("traffic");
    setisloadAD(false);
  };

  const closeAd = () => {
    setprevAd(false);
    elem.classList.remove("fullscreen");
    document.querySelector(".previewTab").classList.remove("show");
    setisloadAD(false);
  };

  const closeAdEscape = useKeyPress("Escape");
  if (closeAdEscape) {
    closeAd();
  }

  function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    // Add event listeners
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
  }

  return (
    <div className={`previewTab ${prevAd ? "show" : ""}`}>
      <div className="rel-sect flex fw fh">
        {/* BUTTONS HEADER */}
        <div
          className={`adNav ${
            BL_videoStatus === "UploadSuccessful" ? "show" : ""
          }`}
        >
          <div className="fullscreen-btn">
            <button
              onClick={goFullScreen}
              className=" py-10 px-25 btn br-7 mr-10"
            >
              <strong>FULLSCREEN</strong>
            </button>

            <button
              onClick={checkVast}
              className="VAST-btn py-10 px-25 btn-warning br-7 mr-10"
            >
              <strong>GET AD XML</strong>
            </button>

            <button
              onClick={closeAd}
              className="VAST-btn py-10 px-25 btn-danger br-7"
            >
              <strong>CLOSE</strong>
            </button>
          </div>
        </div>

        <div className="fakeBtn" onClick={closeAd}></div>

        {/* LOADING FOR ASSET PROCESSING */}
        <div
          className={`AdLoading ${
            BL_videoStatus === "UploadSuccessful" ? "hide" : ""
          }`}
        >
          <div
            className="colw-100"
            style={{ display: videoJobID === "" ? "none" : "block" }}
          >
            <h4>Your assets are being processed</h4>
            <h6 className="mb-20">this may take a while!</h6>
            <VideoLoading
            setnotifyMsg={setnotifyMsg}
              videoJobID={videoJobID}
              BL_videoStatus={BL_videoStatus}
              setBL_videoStatus={setBL_videoStatus}
              videoProgress={videoProgress}
              setvideoProgress={setvideoProgress}
              setgetpreviewVideo={setgetpreviewVideo}
            />

            {fetchingRecipe ? (
              <div className="flex jc-sb ai-c mt-20">
                <div>To cancel the Process</div>
                <div>
                  <button
                    className="btn-danger py-10 px-25 br-7"
                    onClick={(e) => deleteMyVid(e)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`flex fw-w jc-c ai-c showAd p-30 ${
            BL_videoStatus === "UploadSuccessful" ? "show" : ""
          }`}
        >
          <div className="colw-50">
            <div id="player_holder">
              <video id="player">
                <source src={BL_videoURL} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewAD;
