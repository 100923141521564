import React, { useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../lib/UserPool";
import { useHistory } from "react-router";

const Login = ({ isAuthed, setisAuthed }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logState, setlogState] = useState(true);

  const [logMSG, setlogMSG] = useState({
    class: "",
    msg: "",
    delay: 4000,
  });

  let history = useHistory(false);

  const onSubmit = (event) => {
    event.preventDefault();
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const sessionExp = () => {
      const objDate = new Date();
      const hours = objDate.getHours() * 60;
      const minutes = objDate.getMinutes();
      const time = hours + minutes;
      const sessionTime = 60; //in minutes
      const myInterval = setInterval(myTimer, 10000); // set counter of 10 sec

      function myTimer() {
        const objDate = new Date();
        const hours = objDate.getHours() * 60;
        const minutes = objDate.getMinutes();
        const newTime = hours + minutes;
        if (newTime >= time + sessionTime) {
          console.log("Your session of " + sessionTime + "min has expired");
          sessionStorage.removeItem("jwtToken");
          sessionStorage.removeItem("authDate");
          sessionStorage.setItem("isAuth", false);
          document.location.href = "/";
          clearInterval(myInterval);
        }
      }
    };

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        setisAuthed(true);
        sessionStorage.setItem("jwtToken", data.accessToken.jwtToken);

        sessionExp();

        sessionStorage.setItem("isAuth", true);
        const nowDate = Date.now();
        sessionStorage.setItem("authDate", nowDate);
      },
      onFailure: (err) => {
        console.error("onFailure", err);
        setlogMSG({
          class: "error",
          msg: "Please use a valid username and password",
          delay: 6000,
        });
      },
      newPasswordRequired: (data) => {
        console.log("newPasswordRequired", data);
      },
    });
  };

  useEffect(() => {
    if (isAuthed) history.push("/Dashboard");
    if (logMSG.class !== "") {
      setlogState(true);
      setTimeout(() => {
        setlogState(false);
      }, logMSG.delay);
    }
  }, [logMSG, isAuthed, history]);

  if (sessionStorage.getItem("isAuth") === "true") {
    history.push("/Dashboard");
  }

  return (
    <section className="flex fw fh jc-c ai-c primary-bg">
      <div className={`popupmsg ${logState ? logMSG.class : null}`}>
        <div>{logMSG.msg}</div>
      </div>
      <form action="" onSubmit={onSubmit}>
        <div className="loginform br-10 p-30">
          <div className="row" data-spacing="10">
            {/*process.env.REACT_APP_MODE === "DEVELOPMENT" ? (
              <div className="colw-100">
                <div className="main-bg white-col px-20 py-10 br-5 txt-cntr">
                  <h6>You are in a DEVELOPMENT mode!</h6>
                </div>
              </div>
            ) : (
              ""
            )*/}
            <div className="colw-100">
              <h4 className="main-col">Login to the</h4>
              <h2>Self Serve Portal</h2>
            </div>
            <div className="colw-100">
              <label htmlFor="username">Username</label>
              <input
                id="username"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="colw-100">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className="colw-100">
              <button type="submit" className="btn py-15 px-25 colw-100 br-5">
                Login
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Login;
