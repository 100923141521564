import React from "react";
import Brands from "./SideNav/Brands";
import Campaigns from "./SideNav/Campaigns";
import Creatives from "./SideNav/Creatives";

const SideMenu = ({
  brandID,
  campaignID,
  creativeID,
  setBrandID,
  setCampaignID,
  setCreativeID,
  setBrandName,
  setCampaignName,
  setCreativeName,
  brandName,
  campaignName,
  creativeName,
  hasRecipe,
  sethasRecipe,
  setisloadAD,
  fw_counter,
  setfw_counter,
  setToggleState,
  reset_BL_Recipe,
  currentSelect,
  setCurrentSelect,
  setgenVAST,
}) => {
  return (
    <div className="colw-20 res-s-100 p-30 main-bg sidemenu">
      <div className="row" data-spacing="15">
        <Brands
          brandID={brandID}
          brandName={brandName}
          setBrandID={setBrandID}
          setBrandName={setBrandName}
          setCampaignName={setCampaignName}
          setCreativeName={setCreativeName}
          fw_counter={fw_counter}
          setfw_counter={setfw_counter}
          setToggleState={setToggleState}
          reset_BL_Recipe={reset_BL_Recipe}
          currentSelect={currentSelect}
          setCurrentSelect={setCurrentSelect}
          setgenVAST={setgenVAST}
        />
        <Campaigns
          brandID={brandID}
          campaignID={campaignID}
          campaignName={campaignName}
          setCampaignID={setCampaignID}
          setCampaignName={setCampaignName}
          setCreativeName={setCreativeName}
          fw_counter={fw_counter}
          setfw_counter={setfw_counter}
          setToggleState={setToggleState}
          reset_BL_Recipe={reset_BL_Recipe}
          currentSelect={currentSelect}
          setCurrentSelect={setCurrentSelect}
          setgenVAST={setgenVAST}
        />
        <Creatives
          campaignID={campaignID}
          creativeID={creativeID}
          setCreativeID={setCreativeID}
          creativeName={creativeName}
          setCreativeName={setCreativeName}
          hasRecipe={hasRecipe}
          sethasRecipe={sethasRecipe}
          setisloadAD={setisloadAD}
          fw_counter={fw_counter}
          setfw_counter={setfw_counter}
          setToggleState={setToggleState}
          reset_BL_Recipe={reset_BL_Recipe}
          currentSelect={currentSelect}
          setCurrentSelect={setCurrentSelect}
          setgenVAST={setgenVAST}
        />
      </div>
    </div>
  );
};

export default SideMenu;
