import React, { useState, useEffect } from "react";
import S3FileUpload from "react-s3";
import coverPhoto from "../../assets/img/cover.jpg";
import blankPhoto from "../../assets/img/blank.png";
import fakecheckbox from "../../assets/img/checkbox.svg";

const AssetsForm = ({
  brandID,
  campaignID,
  creativeID,
  hasRecipe,
  sethasRecipe,
  brandName,
  campaignName,
  creativeName,
  setnotifyMsg,
  partnerName,
  partnerID,
  setgetCreative,
  toggleState,
  setgetpreviewVideo,
  setVideoJobID,
  setprevAd,
  setrecipeID,
  setfetchingRecipe,
  isloadAD,
  setisloadAD,
  fw_counter,
  setfw_counter,
  spotvid,
  setspotvid,
  bground,
  setbground,
  fground,
  setfground,
  xpos,
  setxpos,
  ypos,
  setypos,
  svwidth,
  setsvwidth,
  videoSize,
  setvideoSize,
  assetPackage,
  setassetPackage,
  formState,
  setFormState,
  prevVid,
  setPrevVid,
  prevbg,
  setPrevbg,
  prevfg,
  setPrevfg,
  assetsArray,
  setAssetsArray,
  uploadedAssetArr,
  setuploadedAssetArr,
  assetsLoading,
  setassetsLoading,
  adGenerating,
  setadGenerating,
  assetsObj,
  setassetsObj,
  fillRecipe,
  setfillRecipe,
  reset_BL_Recipe,
  setgenVAST,
}) => {

  const thisYear = new Date().getFullYear();

  let Addirectory = `partner-assets/${partnerName}-${partnerID}/${thisYear}/${brandName}/${campaignName}-${campaignID}/${creativeName}-${creativeID}/FE-assets`;
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: Addirectory,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  const fullS3Link = "s3://" + config.bucketName + "/" + Addirectory + "/";

  const recipeUrl = process.env.REACT_APP_API_BASE_ROUTE + "ad/recipe";
  const [counter, setcounter] = useState(0);
  const [delay, setDelay] = useState(10000);

  useEffect(() => {
    const getcontSize = () => {
      const genWidth = document.querySelector(".videoViewer").offsetWidth;
      const videowidth = (svwidth * genWidth) / 1920;
      setvideoSize(videowidth);
    };
    getcontSize();
  }, [svwidth, spotvid]);

  const upload_AD_Assets = (e) => {
    e.preventDefault();
    setuploadedAssetArr([]);
    setfw_counter(4);

    let newAssets = [
      assetsObj.assetPackageUri,
      assetsObj.spotVideoUri,
      assetsObj.background,
      assetsObj.foreground,
    ];

    if (assetsObj.background === "") {
      fetch(blankPhoto).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], "bg.png", { contentType });

        let newassetsObj = assetsObj;
        newassetsObj.background = file;
        setassetsObj(newassetsObj);
        console.log(newassetsObj);
        newAssets[2] = file;

        let newfillRecipe = fillRecipe;
        newfillRecipe.imageConfigs[0].imageUri = fullS3Link + file.name;
        setfillRecipe(newfillRecipe);
        console.log(newfillRecipe);
      });
    }

    if (assetsObj.foreground === "") {
      fetch(blankPhoto).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], "fg.png", { contentType });

        let newassetsObj = assetsObj;
        newassetsObj.foreground = file;
        setassetsObj(newassetsObj);
        console.log(newassetsObj);
        newAssets[3] = file;

        let newfillRecipe = fillRecipe;
        newfillRecipe.imageConfigs[1].imageUri = fullS3Link + file.name;
        setfillRecipe(newfillRecipe);
        console.log(newfillRecipe);
      });
    }

    console.log(newAssets);

    if (
      brandName === "" ||
      campaignName === "" ||
      creativeName === "" ||
      spotvid === ""
    ) {
      setnotifyMsg({
        class: "error",
        msg: "Oops! you need to specify all the fields in the form",
        delay: 5000,
      });
    } else {
      // setnotifyMsg({
      //   class: "info",
      //   msg: "your files are beign uploaded please wait a moment",
      //   delay: 7000,
      // });
      setassetsLoading(true);
      setTimeout(() => {
        let s3FilesTracker = [];
        for (let i = 0; i < newAssets.length; i++) {
          S3FileUpload.uploadFile(newAssets[i], config)
            .then((data) => {
              var filestatus = data.key;
              filestatus = filestatus.split("/").pop();
              s3FilesTracker.push(filestatus);
              // console.log(s3FilesTracker);
              setuploadedAssetArr(s3FilesTracker);
              setnotifyMsg({
                class: "success",
                msg: `Your file "${filestatus}" has been uploaded successfully! `,
                delay: 3000,
              });
              if (s3FilesTracker.length === newAssets.length) {
                setTimeout(() => {
                  // sethasRecipe(true);
                  setassetsLoading(false);
                  setfw_counter(6);
                }, 1000);
              }
            })
            .catch((err) => {
              console.error(err);
              setnotifyMsg({
                class: "error",
                msg: "Oops! something went wrong, kindly reupload your spot video files",
                delay: 5000,
              });
            });
        }
      }, 5000);
    }
  };

  const runRecipe = (e) => {
    e.preventDefault();

    if (
      brandName === "" ||
      campaignName === "" ||
      creativeName === "" ||
      spotvid === ""
    ) {
      setnotifyMsg({
        class: "error",
        msg: "Oops! you need to specify all the fields in the form",
        delay: 5000,
      });
    } else {
      // setnotifyMsg({
      //   class: "info bigMsg",
      //   msg: "Please wait while your Ad resources are being processed",
      //   delay: 12000,
      // });
      setadGenerating(true);

      const recipieObj = fillRecipe;

      fetch(recipeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
        },
        body: JSON.stringify(recipieObj),
      })
        .then((response) => response.json())
        .then((json) => {

          if(json.hasOwnProperty('id')){

            // console.log("RECIPE RESPONSE");
            // console.log(json);
            setgetCreative(json.tempIframeUrl);
            setrecipeID(json.id);
            // console.log(json.id);

            const videoURI = json.outputs[1].uri;
            const videoURL = videoURI.replace(
              `s3://${config.bucketName}/`,
              `https://${config.bucketName}.s3.${config.region}.amazonaws.com/`
            );
            setgetpreviewVideo(videoURL);
            // console.log(videoURL);
            // console.log("RECIPE RESPONSE");
            setprevAd(true);
            setVideoJobID(json.videoJobId);
            setadGenerating(false);
            setfetchingRecipe(true);
            setisloadAD(true);
            sethasRecipe(true);

          } else{
            // this line is needed to trigger the useEffect  
            setDelay(delay === 10000 ? 9000 : 10000);
            if (
              json.error === "UnexpectedError" ||
              json.message === "unexpected error on asset package processing"
            ) {
              // console.log(json);
              setTimeout(() => {
                setnotifyMsg({
                  class: "error",
                  msg: `Oops! it seams your assets are not fitting our criteria, check the kick starter in the Resources tab!`,
                  delay: delay,
                });
                setadGenerating(false);
              }, 1000);
            }
            
            else if (json.message === "partner ID not registered") {
              // console.log(json);
              setTimeout(() => {
                setnotifyMsg({
                  class: "error",
                  msg: `Oops! it seams your assets are not fitting our criteria, check the kick starter in the Resources tab!`,
                  delay: delay,
                });
                setadGenerating(false);
              }, 1000);
            }

            else if (json.message === "input video file not found") {
              // console.log(json);
              setTimeout(() => {
                setnotifyMsg({
                  class: "error",
                  msg: `Oops! it seams the video input uploaded is lost!`,
                  delay: delay,
                });
                setadGenerating(false);
              }, 1000);
            }
            
            else {
              // console.log(json);
              setTimeout(() => {
                setnotifyMsg({
                  class: "error",
                  msg: `
                    Oops! something went wrong while processing the video, kindly
                    <a class="white-color" href="mailto:support@brightline.tv">contact us</a>
                  `,
                  delay: delay,
                });
                setadGenerating(false);
              }, 1000);
            }

          }
        }).catch( () => {
          setDelay(delay === 10000 ? 9000 : 10000);
          setTimeout(() => {
            setnotifyMsg({
              class: "error",
              msg: `Oops! something went wrong. Please refresh the page!`,
              delay: delay,
            });
            setadGenerating(false);
          }, 1000);
        });
    }
  };

  useEffect(() => {
    if (spotvid !== "") {
      setFormState("show upload assets");
    }

    if (hasRecipe) {
      setnotifyMsg({
        class: "info",
        msg: "Oh! it seams we have a an AD generated on this Creative",
        delay: 8000,
      });
      let recipieFetch = `${process.env.REACT_APP_API_BASE_ROUTE}ad/recipe/by-creative/${creativeID}`;
      let req = new Request(recipieFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
        },
        mode: "cors",
      });

      fetch(req)
        .then((res) => {
          return res.json();
        })
        .then((jsonData) => {
          // console.log(jsonData);
          if (
            jsonData.message ===
            "recipe not found or belongs to a different partner"
          ) {
            const changeValues = () => {
              if (counter === 0) {
                setassetPackage("");
                setspotvid("");
                setPrevbg("");
                setbground("");
                setPrevfg("");
                setfground("");
                setxpos("");
                setypos("");
                setsvwidth("");
                //let user generate AD
                setfetchingRecipe(false);
                setVideoJobID("");
                setgetCreative("");
                setrecipeID("");
                setgetpreviewVideo("");
                setprevAd(false);
                setcounter(counter + 1);
              }
            };
            changeValues();
          } else {
            setPrevVid(coverPhoto);

            // Displaying the name of the zip file
            let dispAssetpackage = jsonData.assetPackageUri;
            let newdispAssetpackage = dispAssetpackage.substring(
              dispAssetpackage.lastIndexOf("/") + 1
            );
            setassetPackage(newdispAssetpackage);

            // Displaying the name of the Video file
            let dispvideoLink = jsonData.spotVideoUri;
            let newdispvideoLink = dispvideoLink.substring(
              dispvideoLink.lastIndexOf("/") + 1
            );
            setspotvid(newdispvideoLink);

            // Displaying the image Background
            let newBGPrevIMG = jsonData.images[0].imageUri.replace(
              `s3://${config.bucketName}/`,
              `https://${config.bucketName}.s3.${config.region}.amazonaws.com/`
            );
            setPrevbg(newBGPrevIMG);

            // Displaying the name of the BG file
            let dispBGLink = jsonData.images[0].imageUri;
            let newdispBGLink = dispBGLink.substring(
              dispBGLink.lastIndexOf("/") + 1
            );
            setbground(newdispBGLink);

            // Displaying the image ForeGround
            let newFGPrevIMG = jsonData.images[1].imageUri.replace(
              `s3://${config.bucketName}/`,
              `https://${config.bucketName}.s3.${config.region}.amazonaws.com/`
            );
            setPrevfg(newFGPrevIMG);

            // Displaying the name of the BG file
            let dispFGLink = jsonData.images[1].imageUri;
            let newdispFGLink = dispFGLink.substring(
              dispFGLink.lastIndexOf("/") + 1
            );
            setfground(newdispFGLink);

            setxpos(jsonData.spotPosX);
            setypos(jsonData.spotPosY);
            setsvwidth(jsonData.widthSize);
            setgetCreative(jsonData.tempIframeUrl);
            setrecipeID(jsonData.id);

            const videoURI = jsonData.outputs[0].uri;
            const videoURL = videoURI.replace(
              `s3://${config.bucketName}/`,
              `https://${config.bucketName}.s3.${config.region}.amazonaws.com/`
            );
            setgetpreviewVideo(videoURL);
            // console.log("MY VIDEO URL IS: ", videoURL);

            setVideoJobID(jsonData.videoJobId);

            //SHOW THE RECIPE ON CREATIVE CHANGE
            // setprevAd(true);
          }
        });
    }
  }, [
    formState,
    creativeID,
    prevbg,
    setuploadedAssetArr,
    config.bucketName,
    config.region,
    counter,
    // hasRecipe,
    setVideoJobID,
    setfetchingRecipe,
    setgetCreative,
    setgetpreviewVideo,
    setprevAd,
    setrecipeID,
  ]);

  const setAssets = (e) => {
    let newassetsObj = assetsObj;
    newassetsObj.assetPackageUri = e.target.files[0];
    setassetsObj(newassetsObj);

    let newfillRecipe = fillRecipe;
    newfillRecipe.creativeId = creativeID;

    if(e.target.files.length > 0){
      newfillRecipe.assetPackageUri = fullS3Link + e.target.files[0].name;
      setassetPackage(e.target.files[0].name);

      setfillRecipe(newfillRecipe);

      let cloneArr = [...assetsArray];

      cloneArr.forEach((a, i) => {
        let checkvidFile = a.name;

        const getExt = checkvidFile.split(".").pop();
        if (getExt === "zip" || getExt === "rar" || getExt === "7zip") {
          cloneArr.splice(i, 1);
        }
      });

      cloneArr.push(e.target.files[0]);
      setAssetsArray(cloneArr);
      setfw_counter(4);
    } else{

      newfillRecipe.assetPackageUri = "";
      setassetPackage("");
      setfillRecipe(newfillRecipe);
      setfw_counter(3);

    }
    
  };

  const vidChanged = (e) => {
    let newVideo = e.target.files[0];
    if(newVideo) {
      let newassetsObj = assetsObj;
      newassetsObj.spotVideoUri = newVideo;
      setassetsObj(newassetsObj);
  
      let newfillRecipe = fillRecipe;
      newfillRecipe.spotVideoUri = fullS3Link + newVideo.name;
      setfillRecipe(newfillRecipe);
  
      setspotvid(e.target.files[0].name);
      setPrevVid(coverPhoto);
      let cloneArr = [...assetsArray];
  
      cloneArr.forEach((a, i) => {
        let checkvidFile = a.name;
  
        const getExt = checkvidFile.split(".").pop();
        if (getExt === "mov" || getExt === "mp4") {
          cloneArr.splice(i, 1);
        }
      });
  
      cloneArr.push(newVideo);
      setAssetsArray(cloneArr);
      setfw_counter(5);
    }
  };

  const bgChanged = (e) => {
    if (e.target.value !== "") {
      setbground(e.target.files[0].name);
      setPrevbg(URL.createObjectURL(e.target.files[0]));
      let cloneArr = [...assetsArray];

      cloneArr.forEach((a, i) => {
        let checkvidFile = a.name;

        const getExtPNG = checkvidFile.includes("bg.png");
        const getExtJPG = checkvidFile.includes("bg.jpg");
        if (getExtPNG || getExtJPG) {
          cloneArr.splice(i, 1);
        }
      });

      let newassetsObj = assetsObj;
      newassetsObj.background = e.target.files[0];
      setassetsObj(newassetsObj);

      let newfillRecipe = fillRecipe;
      newfillRecipe.imageConfigs[0].imageUri =
        fullS3Link + e.target.files[0].name;
      setfillRecipe(newfillRecipe);

      cloneArr.push(e.target.files[0]);
      setAssetsArray(cloneArr);
      // console.log(cloneArr);
      setfw_counter(5);
    }
  };

  const fgChanged = (e) => {
    if (e.target.value !== "") {
      setfground(e.target.files[0].name);
      setPrevfg(URL.createObjectURL(e.target.files[0]));
      let cloneArr = [...assetsArray];

      cloneArr.forEach((a, i) => {
        let checkvidFile = a.name;

        const getExtPNG = checkvidFile.includes("fg.png");
        if (getExtPNG) {
          cloneArr.splice(i, 1);
        }
      });

      let newassetsObj = assetsObj;
      newassetsObj.foreground = e.target.files[0];
      setassetsObj(newassetsObj);

      let newfillRecipe = fillRecipe;
      newfillRecipe.imageConfigs[1].imageUri =
        fullS3Link + e.target.files[0].name;
      setfillRecipe(newfillRecipe);

      cloneArr.push(e.target.files[0]);
      setAssetsArray(cloneArr);
      // console.log(cloneArr);
      setfw_counter(5);
    }
  };

  const xPosChanged = (e) => {
    setxpos(e.target.value);
    let newfillRecipe = fillRecipe;
    newfillRecipe.spotPosition.x = e.target.value;
    setfillRecipe(newfillRecipe);
  };

  const yPosChanged = (e) => {
    setypos(e.target.value);
    let newfillRecipe = fillRecipe;
    newfillRecipe.spotPosition.y = e.target.value;
    setfillRecipe(newfillRecipe);
  };

  const svWidthChanged = (e) => {
    setsvwidth(e.target.value);
    let newfillRecipe = fillRecipe;
    newfillRecipe.widthSize = e.target.value;
    setfillRecipe(newfillRecipe);
  };

  const videoField = () => {
    if (spotvid === "") {
      return "Upload the Spot Video";
    } else {
      return spotvid;
    }
  };

  const bgField = () => {
    if (bground === "") {
      return "Upload Background";
    } else {
      return bground;
    }
  };

  const fgField = () => {
    if (fground === "") {
      return "Upload Foreground";
    } else {
      return fground;
    }
  };
  bgField();
  fgField();
  videoField();

  const delayActiveClass = () => {
    setTimeout(() => {
      return true;
    }, 2000);
  };

  return (
    <div
      className={toggleState === "upload" ? "active" : null}
      data-tab="upload"
    >
      <div
        className={`uploadingAssets flex jc-sb ai-c ${
          assetsLoading ? "show" : ""
        } ${adGenerating ? "hide" : ""} `}
      >
        <div className="row rel-sect">
          <div className="colw-100">
            <h5>
              Kindly note that your assets are being uploaded this may take a
              bit of time
            </h5>
            <h6>depending on the size of the files</h6>
          </div>
          <div className="colw-100 mt-15">
            <div className="flex">
              <div className="pr-20">Uploaded Files:</div>
              {uploadedAssetArr.map((asset, index) => (
                <div
                  key={index}
                  className={`flex ai-c assetfile pr-40 ${
                    delayActiveClass ? "show" : ""
                  }`}
                >
                  <div className="pr-10 fakecheckbox">
                    <img src={fakecheckbox} alt="" />
                  </div>
                  <div>{asset}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="loadingMSG">
          <div className="flex jc-c ai-c">
            <div className="pr-20">
              <h6>Loading...</h6>
            </div>
            <div className="msg-loading"></div>
          </div>
        </div>
      </div>

      <div
        className={`genearateADRecipe flex jc-sb ai-c ${
          adGenerating ? "show" : ""
        } `}
      >
        <div className="row rel-sect">
          <div className="colw-100">
            <h5>Your AD is generating, please sit back and relax</h5>
            <h6>this may take a little bit of time</h6>
          </div>
        </div>
        <div className="loadingMSG">
          <div className="flex jc-c ai-c">
            <div className="pr-20">
              <h6>Loading...</h6>
            </div>
            <div className="msg-loading"></div>
          </div>
        </div>
      </div>

      <div className="flex fw-w">
        <div className="colw-33 p-30">
          <div className="row" data-spacing={5}>
            <div
              className={`colw-100 valField ${
                fw_counter > 2 ? "" : "disabled"
              }`}
            >
              <h5>Upload Asset Package</h5>
              <div className="row fileInput mt-5" data-spacing="5">
                <div className="colw-100">
                  <input
                    type="file"
                    id="assetInput"
                    onChange={setAssets}
                    accept=".zip,.rar,.7zip"
                  />
                  <label className="br-5" htmlFor="assetInput">
                    {assetPackage === ""
                      ? "Upload asset package"
                      : assetPackage}
                  </label>
                  <div className="fileList" />
                </div>
              </div>
            </div>
            <div
              className={`colw-100 mt-20 br-8 valField ${
                fw_counter > 3 ? "" : "disabled"
              }`}
              style={{ backgroundColor: "#fff" }}
            >
              <div className="row p-20" data-spacing="5">
                <div className="colw-100">
                  <div>
                    <h5>Spot Video Assets</h5>
                  </div>
                </div>
                <div className="colw-100">
                  <div className="fileInput">
                    <input
                      type="file"
                      id="spotVidInput"
                      onChange={vidChanged}
                      accept="video/mp4,video/x-m4v,video/*"
                    />
                    <label className="br-5" htmlFor="spotVidInput">
                      {videoField()}
                    </label>
                    <div className="fileList" />
                  </div>
                </div>
                <div className="colw-100 spotSpecs">
                  <div className="flex ai-c">
                    <div className="colw-40 pr-10">Spot Video Position</div>
                    <div className="colw-30 pl-10">
                      <input
                        className="br-5"
                        type="number"
                        placeholder="X Position"
                        id="videoX"
                        onChange={xPosChanged}
                        step="1"
                        min="0"
                        max="1920"
                        value={xpos}
                      />
                    </div>
                    <div className="colw-30 pl-10">
                      <input
                        className="br-5"
                        type="number"
                        placeholder="Y Position"
                        id="videoY"
                        onChange={yPosChanged}
                        step="1"
                        min="0"
                        max="500"
                        value={ypos}
                      />
                    </div>
                  </div>
                </div>
                <div className="colw-100 spotSpecs">
                  <div className="flex ai-c">
                    <div className="colw-33 pr-10">Spot Video width</div>
                    <div className="colw-66 pl-10">
                      <input
                        className="br-5"
                        type="number"
                        placeholder="width"
                        id="videoWidth"
                        onChange={svWidthChanged}
                        step="1"
                        min="320"
                        max="1920"
                        value={svwidth}
                      />
                    </div>
                  </div>
                </div>
                <div className="colw-100">
                  <div className="fileInput">
                    <input
                      type="file"
                      id="spotBgInput"
                      onChange={bgChanged}
                      accept="image/*"
                    />
                    <label className="br-5" htmlFor="spotBgInput">
                      {bgField()}
                    </label>
                    <div className="fileList" />
                  </div>
                </div>
                <div className="colw-100" id="listOfElements">
                  <div className="flex ai-c element">
                    <div className="colw-100">
                      <div className="fileInput">
                        <input
                          type="file"
                          id="spotFgInput"
                          onChange={fgChanged}
                          accept="image/*"
                        />
                        <label className="br-5" htmlFor="spotFgInput">
                          {fgField()}
                        </label>
                        <div className="fileList" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`colw-100 valField rel-sect ${
                    fw_counter > 4 ? "" : "disabled"
                  } `}
                >
                  <button
                    className="btn py-15 px-30 br-10 colw-100"
                    onClick={(e) => upload_AD_Assets(e)}
                  >
                    <strong>UPLOAD ASSETS</strong>
                    <div
                      className={`btn-loading ${assetsLoading ? "show" : ""}`}
                    ></div>
                  </button>
                </div>
              </div>
            </div>
            <div className="colw-100">
              <hr className="my-15" />
            </div>
            {hasRecipe ? (
              <div>
                <button
                  className="btn py-15 px-30 br-10 colw-100"
                  onClick={reset_BL_Recipe}
                >
                  <strong>RESET AD</strong>
                </button>
              </div>
            ) : (
              <div
                className={`colw-100 valField rel-sect ${
                  fw_counter > 5 ? "" : "disabled"
                } ${assetsLoading ? "disabled" : ""}`}
              >
                <button
                  className="btn py-15 px-30 br-10 colw-100"
                  onClick={runRecipe}
                >
                  <strong>GENERATE AD</strong>
                  <div
                    className={`btn-loading  ${adGenerating ? "show" : ""}`}
                  ></div>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="colw-66 pt-30">
          <div className="pl-20 pr-40">
            <div id="videoViewer" className="videoViewer">
              <img className="overlayBg" id="overlayBg" alt="" src={prevbg} />
              <img className="overlayBg" id="overlayBg2" alt="" src={prevfg} />
              <div
                id="videoPrev"
                style={{
                  width: videoSize + "px",
                  height: videoSize / 1.77 + "px",
                  top: ypos + "px",
                  left: xpos + "px",
                  backgroundImage: `url(${prevVid})`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetsForm;
