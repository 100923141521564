import React, { useState, useEffect } from "react";
import SideMenu from "../components/SideMenu";
import Interface from "../components/Interface";
import Header from "../components/Header";
import { useHistory } from "react-router";

const Dashboard = ({ isAuthed, isNow }) => {
  const tokenTime = 3600000;
  const [brandID, setBrandID] = useState("");
  const [campaignID, setCampaignID] = useState("");
  const [creativeID, setCreativeID] = useState("");
  const [brandName, setBrandName] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [creativeName, setCreativeName] = useState("");
  const [partnerName, setpartnerName] = useState("");
  const [partnerID, setpartnerID] = useState("");
  const [partnerLogo, setpartnerLogo] = useState("");
  const [getCreative, setgetCreative] = useState("");
  const [getpreviewVideo, setgetpreviewVideo] = useState("");
  const [BL_videoStatus, setBL_videoStatus] = useState("Getting ready");
  const [genVAST, setgenVAST] = useState("");
  const [videoJobID, setVideoJobID] = useState("");
  const [videoProgress, setvideoProgress] = useState(0);
  const [prevAd, setprevAd] = useState(false);
  const [recipeID, setrecipeID] = useState("");
  const [fetchingRecipe, setfetchingRecipe] = useState(false);
  const [hasRecipe, sethasRecipe] = useState(false);
  const [isloadAD, setisloadAD] = useState(false);
  const [fw_counter, setfw_counter] = useState(0);
  const [toggleState, setToggleState] = useState("upload");
  
  const [spotvid, setspotvid] = useState("");
  const [bground, setbground] = useState("");
  const [fground, setfground] = useState("");
  const [xpos, setxpos] = useState(0);
  const [ypos, setypos] = useState(0);
  const [svwidth, setsvwidth] = useState(1920);
  const [videoSize, setvideoSize] = useState(1920);
  const [assetPackage, setassetPackage] = useState("");
  const [formState, setFormState] = useState("");

  const [prevVid, setPrevVid] = useState("");
  const [prevbg, setPrevbg] = useState("");
  const [prevfg, setPrevfg] = useState("");
  const [assetsArray, setAssetsArray] = useState([]);
  const [uploadedAssetArr, setuploadedAssetArr] = useState([]);
  const [assetsLoading, setassetsLoading] = useState(false);
  const [adGenerating, setadGenerating] = useState(false);

  const [currentSelect, setCurrentSelect] = useState("");

  const [assetsObj, setassetsObj] = useState({
    assetPackageUri: "",
    spotVideoUri: "",
    background: "",
    foreground: "",
  });

  const [fillRecipe, setfillRecipe] = useState({
    creativeId: "",
    assetPackageUri: "",
    spotVideoUri: "",
    spotPosition: {
      x: 0,
      y: 0,
    },
    widthSize: 1920,
    imageConfigs: [
      {
        imageUri: "",
        zIndex: -1,
      },
      {
        imageUri: "",
        zIndex: 1,
      },
    ],
  });

  const [notifyMsg, setnotifyMsg] = useState({
    class: "",
    msg: "",
    delay: 4000,
  });
  const [msgState, setmsgState] = useState(true);

  let history = useHistory(false);

  useEffect(() => {
    if (notifyMsg.class !== "") {
      setmsgState(true);
      setTimeout(() => {
        setmsgState(false);
      }, notifyMsg.delay);
    }

    if (isAuthed) {
      let thisnow = sessionStorage.getItem("authDate");
      const valid = parseInt(thisnow) + tokenTime;

      setInterval(() => {
        let newNow = Date.now();
        if (newNow >= valid) {
          window.sessionStorage.clear();
          window.location.href = "/";
          clearInterval();
        }
      }, 3000);
    }
  }, [setmsgState, notifyMsg.class, notifyMsg.delay, isAuthed, history]);

  const noTockenRedirect = () => {
    if (sessionStorage.getItem("isAuth") !== "true") {
      history.push("/");
    }
  };
  noTockenRedirect();

  const reset_BL_Recipe = () => {
    sethasRecipe(false);
    setfillRecipe({
      creativeId: creativeID,
      assetPackageUri: "",
      spotVideoUri: "",
      spotPosition: {
        x: 0,
        y: 0,
      },
      widthSize: 1920,
      imageConfigs: [
        {
          imageUri: "",
          zIndex: -1,
        },
        {
          imageUri: "",
          zIndex: 1,
        },
      ],
    });

    setassetsObj({
      assetPackageUri: "",
      spotVideoUri: "",
      background: "",
      foreground: "",
    });
    
   // reset inputs
    document.getElementById("assetInput").value = null;
    document.getElementById("spotVidInput").value = null;
    document.getElementById("spotBgInput").value = null;
    document.getElementById("spotFgInput").value = null;

    setxpos(0);
    setypos(0);
    setassetPackage("");
    setspotvid("");
    setsvwidth(1920);
    setPrevVid("");
    setPrevbg("");
    setfground("");
    setbground("");
    setPrevfg("");
    setgenVAST("");
    setgetCreative("");
    setBL_videoStatus("Getting ready");
    setisloadAD(false);
    setAssetsArray([]);
    setrecipeID("");
    setToggleState("upload");
  };
  
  return (
    <>
      <div className={`popupmsg ${msgState ? notifyMsg.class : null}`}>
        <div dangerouslySetInnerHTML={{ __html: notifyMsg.msg }} />
      </div>
      <Header
        partnerName={partnerName}
        setpartnerName={setpartnerName}
        setpartnerID={setpartnerID}
        partnerLogo={partnerLogo}
        setpartnerLogo={setpartnerLogo}
      />
      <main className="flex ai-s fw-w">
        <SideMenu
          brandID={brandID}
          setBrandID={setBrandID}
          campaignID={campaignID}
          setCampaignID={setCampaignID}
          creativeID={creativeID}
          setCreativeID={setCreativeID}
          brandName={brandName}
          setBrandName={setBrandName}
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          creativeName={creativeName}
          setCreativeName={setCreativeName}
          hasRecipe={hasRecipe}
          sethasRecipe={sethasRecipe}
          setisloadAD={setisloadAD}
          fw_counter={fw_counter}
          setfw_counter={setfw_counter}
          toggleState={toggleState}
          setToggleState={setToggleState}
          reset_BL_Recipe={reset_BL_Recipe}
          currentSelect={currentSelect}
          setCurrentSelect={setCurrentSelect}
          setgenVAST={setgenVAST}
        />
        <Interface
          brandName={brandName}
          brandID={brandID}
          campaignName={campaignName}
          campaignID={campaignID}
          creativeName={creativeName}
          creativeID={creativeID}
          setnotifyMsg={setnotifyMsg}
          partnerName={partnerName}
          partnerID={partnerID}
          setBrandName={setBrandName}
          setCampaignName={setCampaignName}
          setCreativeName={setCreativeName}
          getCreative={getCreative}
          setgetCreative={setgetCreative}
          getpreviewVideo={getpreviewVideo}
          setgetpreviewVideo={setgetpreviewVideo}
          BL_videoStatus={BL_videoStatus}
          setBL_videoStatus={setBL_videoStatus}
          genVAST={genVAST}
          setgenVAST={setgenVAST}
          videoJobID={videoJobID}
          setVideoJobID={setVideoJobID}
          videoProgress={videoProgress}
          setvideoProgress={setvideoProgress}
          prevAd={prevAd}
          setprevAd={setprevAd}
          recipeID={recipeID}
          setrecipeID={setrecipeID}
          fetchingRecipe={fetchingRecipe}
          setfetchingRecipe={setfetchingRecipe}
          hasRecipe={hasRecipe}
          sethasRecipe={sethasRecipe}
          isloadAD={isloadAD}
          setisloadAD={setisloadAD}
          fw_counter={fw_counter}
          setfw_counter={setfw_counter}
          toggleState={toggleState}
          setToggleState={setToggleState}
          spotvid={spotvid}
          setspotvid={setspotvid}
          bground={bground}
          setbground={setbground}
          fground={fground}
          setfground={setfground}
          xpos={xpos}
          setxpos={setxpos}
          ypos={ypos}
          setypos={setypos}
          svwidth={svwidth}
          setsvwidth={setsvwidth}
          videoSize={videoSize}
          setvideoSize={setvideoSize}
          assetPackage={assetPackage}
          setassetPackage={setassetPackage}
          formState={formState}
          setFormState={setFormState}
          prevVid={prevVid}
          setPrevVid={setPrevVid}
          prevbg={prevbg}
          setPrevbg={setPrevbg}
          prevfg={prevfg}
          setPrevfg={setPrevfg}
          assetsArray={assetsArray}
          setAssetsArray={setAssetsArray}
          uploadedAssetArr={uploadedAssetArr}
          setuploadedAssetArr={setuploadedAssetArr}
          assetsLoading={assetsLoading}
          setassetsLoading={setassetsLoading}
          adGenerating={adGenerating}
          setadGenerating={setadGenerating}
          assetsObj={assetsObj}
          setassetsObj={setassetsObj}
          fillRecipe={fillRecipe}
          setfillRecipe={setfillRecipe}
          reset_BL_Recipe={reset_BL_Recipe}
        />
      </main>
    </>
  );
};

export default Dashboard;
