import React, { useState } from "react";

const TrafficTab = ({
  apiBaseUrl,
  toggleState,
  genVAST,
  setgenVAST,
  recipeID,
  setnotifyMsg,
}) => {
  const [isGettingVast, setisGettingVast] = useState(false);
  const VAST_Url = `${process.env.REACT_APP_API_BASE_ROUTE}ad/recipe/${recipeID}/publish`;

  const generateVAST = () => {
    if (recipeID !== "") {
      setisGettingVast(true);
      fetch(VAST_Url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
        },
        body: null,
      })
        .then((res) => {
          return res.json();
        })
        .then((jsonData) => {
          setnotifyMsg({
            class: "success",
            msg: `The VAST is now Published and the link has been copied to your clipboard!`,
            delay: 5000,
          });
          setgenVAST(jsonData.publishedAds[0].cdnVastUrl);
          navigator.clipboard.writeText(jsonData.publishedAds[0].cdnVastUrl);
          setTimeout(() => {
            setisGettingVast(false);
          }, 1000);
        });
    }
  };

  return (
    <div
      data-tab="traffic"
      className={toggleState === "traffic" ? "active p-30" : "p-30"}
    >
      <div
        className={`genearateADRecipe flex jc-sb ai-c ${
          isGettingVast ? "show" : ""
        } `}
      >
        <div className="row rel-sect">
          <div className="colw-100">
            <h5>We are generating your VAST</h5>
            <h6>it will only take a second</h6>
          </div>
        </div>
        <div className="loadingMSG">
          <div className="flex jc-c ai-c">
            <div className="pr-20">
              <h6>Loading...</h6>
            </div>
            <div className="msg-loading"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="colw-33">
          <div className="row" data-spacing={5}>
            <div className="colw-100">
              <input
                className="br-5"
                type="text"
                placeholder="VAST URL"
                value={genVAST}
                readOnly
              />
            </div>
            <div className="colw-100">
              <button
                className="btn py-15 px-30 br-10 colw-100"
                onClick={generateVAST}
              >
                <strong>GENERATE VAST URL</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrafficTab;
