import Login from "./views/Login";
import LandingPage from "./views/LandingPage";
import Dashboard from "./views/Dashboard";
import Platforms from "./views/Platforms";
import { BrowserRouter as Router, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";

function App() {
  const [isAuthed, setisAuthed] = useState(false);
  const [isNow, setisNow] = useState(false);
  let myInterval

  useEffect(() => {
    const sessionTime = 60 * 60 * 1000

    const myTimer = () => {

      const time = sessionStorage.getItem("authDate")
      const date = new Date(); // some mock date
      const newTime = date.getTime(); 

      if (parseInt(newTime) >= parseInt(time) + sessionTime) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.removeItem("authDate");
          sessionStorage.setItem("isAuth", false);
          document.location.href = "/";
          clearInterval(myInterval);
      }
    }

    if(sessionStorage.getItem("isAuth") === "true"){
        myInterval = setInterval(myTimer, 10000);
    }

  });

  return (
    <>
      {console.log("BrightLink is up and runing")}
      <Router>
        <Route
          exact
          path="/"
          component={() => (
            <Login setisAuthed={setisAuthed} isAuthed={isAuthed} />
          )}
        />
        <Route
          path="/LandingPage"
          component={() => (
            <LandingPage isAuthed={isAuthed} setgetNow={setisNow} />
          )}
        />
        <Route
          path="/Dashboard"
          component={() => <Dashboard isAuthed={isAuthed} getNow={isNow} />}
        />
        <Route path="/Platforms" component={Platforms} />
      </Router>
    </>
  );
}

export default App;
