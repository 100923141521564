import React from "react";

const Navigation = ({
  toggleState,
  setToggleState,
  hasRecipe,
  setprevAd,
  setisloadAD,
}) => {
  const toggleTab = (e) => {
    if (hasRecipe) setToggleState(e.target.dataset.tab);
  };

  const toggleTabNoCondition = (e) => {
    setToggleState(e.target.dataset.tab);
  };

  const showBLAd = (e) => {
    if (!e.target.classList.contains("disabled")) {
      setprevAd(true);
      setisloadAD(true);
      if (hasRecipe)
        setTimeout(() => { document.querySelector(".previewTab").classList.add("show"); }, 100)
    }
  };

  return (
    <div className="flex tabmenu">
      <div
        className={toggleState === "upload" ? "active" : null}
        data-tab="upload"
        onClick={(e) => toggleTabNoCondition(e)}
      >
        Upload
      </div>
      <div
        className={`showBLAd ${!hasRecipe ? "disabled" : ""}`}
        data-tab="preview"
        onClick={showBLAd}
      >
        Preview
      </div>
      <div
        className={`${toggleState === "traffic" ? "active" : "null"} ${
          !hasRecipe ? "disabled" : ""
        }`}
        data-tab="traffic"
        onClick={(e) => toggleTab(e)}
      >
        Traffic
      </div>
      <div>
        <a
          href="https://app.powerbi.com/"
          target="_blank"
          rel="noreferrer"
          alt="Report"
        >
          Report
        </a>
      </div>
      <div
        className={toggleState === "resources" ? "active" : null}
        data-tab="resources"
        onClick={(e) => toggleTabNoCondition(e)}
      >
        Resources
      </div>
    </div>
  );
};

export default Navigation;
