import React, { useEffect } from "react";
import BL_Logo from "../assets/img/BrightLink.png";
import BL_logout from "../assets/img/logout.svg";

const Header = ({
  partnerName,
  setpartnerName,
  setpartnerID,
  partnerLogo,
  setpartnerLogo,
}) => {
  const partnerURL = process.env.REACT_APP_API_BASE_ROUTE + "me";

  useEffect(() => {
    let isMounted = true;

    if (sessionStorage.getItem("isAuth") === "true") {
      let req = new Request(partnerURL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
        },
        mode: "cors",
      });

      fetch(req)
        .then((res) => {
          return res.json();
        })
        .then((jsonData) => {
          if (isMounted) {
            setpartnerLogo(jsonData.logoUrl);
            setpartnerName(jsonData.partnerName);
            setpartnerID(jsonData.partnerId);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [partnerURL, setpartnerID, setpartnerName, setpartnerLogo]);

  const onLogoutClick = () => {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("authDate");
    sessionStorage.setItem("isAuth", false);
    document.location.href = "/";
  };

  return (
    <header className="flex jc-sb ai-c p-30">
      <div>
        <img src={BL_Logo} alt="BrightLine" className="logo" />
      </div>
      <div className="flex jc-sb ai-c">
        <div>
          <img src={partnerLogo} alt="Partner" className="partner-logo" />
        </div>
        <div className="pl-30">
          <button
            className="logoutButton btn-danger py-10 px-20 br-10"
            onClick={onLogoutClick}
          >
            <img src={BL_logout} alt="logout" className="logout" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
