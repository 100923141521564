import React from "react";
let isError = false;

const VideoLoading = ({
  videoJobID,
  BL_videoStatus,
  setBL_videoStatus,
  videoProgress,
  setvideoProgress,
  setnotifyMsg,
}) => {
  let videoTracker = `${process.env.REACT_APP_API_BASE_ROUTE}video/${videoJobID}`;

  const myTimer = () => {
    var funct = setInterval(function () {
      if (videoJobID !== "") {
        let req = new Request(videoTracker, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
          },
          mode: "cors",
        });

        fetch(req)
          .then((res) => {
            return res.json();
          })
          .then((jsonData) => {
            console.log("-------> jsonData returned");
            console.log(jsonData);
            console.log("-------> jsonData returned");

            if (jsonData.logs.length > 0) {
              setBL_videoStatus(jsonData.logs[0].status);
              setvideoProgress(jsonData.logs[0].progress);
            } else {
              setnotifyMsg({
                class: "error",
                msg: `
                      ${
                        jsonData.errorMessage + " error " + jsonData.errorCode
                      }  Oops! something went wrong while processing the video, kindly
                        <a class="white-color" href="mailto:support@brightline.tv">contact us</a>
                        <div class="close_notification" id="close_notification">x</div>
                      if this happens again
                    `,

                delay: 60 * 60 * 1000,
              });
              isError = true;
              clearInterval(funct);
            }

            if (
              jsonData.latestStatus === "ForceShutdownError" ||
              jsonData.latestStatus === "UnexpectedError" ||
              jsonData.latestStatus === "GracefulCancellation" ||
              jsonData.latestStatus === "UploadSuccessful"
            ) {
              if (
                jsonData.latestStatus === "ForceShutdownError" ||
                jsonData.latestStatus === "UnexpectedError"
              ) {
                setnotifyMsg({
                  class: "error",

                  msg: `
                      Oops! something went wrong while processing the video, kindly
                        <a class="white-color" href="mailto:support@brightline.tv">contact us</a>
                        <div class="close_notification" id="close_notification">x</div>
                      if this happens again
                    `,

                  delay: 60 * 60 * 1000,
                });
              }
              isError = true;
              clearInterval(funct);
            }
          });
      }
    }, 10000);
  };
  if (isError === false) {
    myTimer();
  }

  if (document.getElementById("close_notification")) {
    document
      .getElementById("close_notification")
      .addEventListener("click", () => {
        document.querySelector(".popupmsg").style.display = "none";
      });
  }

  return (
    <div className="flex">
      <div>{BL_videoStatus}</div>
      <div className="fg-3 pl-15 rel-sect mt-10">
        <div
          className="loadingVideo"
          style={{ width: `${videoProgress * 100}%` }}
        ></div>
      </div>
    </div>
  );
};

export default VideoLoading;
