import React, { useState, useEffect } from "react";

const Brands = ({ setBrandID, token }) => {
  const [posts, setPosts] = useState([]);
  const PlatformsUrl = "http:https://ssp.api.brightline-dev.com/platforms";

  useEffect(() => {
    let req = new Request(PlatformsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
      },
      mode: "cors",
    });

    fetch(req)
      .then((res) => {
        return res.json();
      })
      .then((jsonData) => {
        if (jsonData.length === 0) {
          setPosts([{ id: "NA", name: "No data" }]);
        } else {
          setPosts(jsonData);
        }
      });
  }, [PlatformsUrl]);

  return (
    <div className="colw-100">
      {posts.map((post) => (
        <div>
          <div>
            <span>Platform ID: </span>
            <span>{post.platformId}</span>
            <span> ----- </span>
            <span>Platform Name: </span>
            <span>{post.platformName}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Brands;
