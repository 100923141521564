import React from "react";
import download_icon from "../../assets/img/Download.png";

const ResourcesTab = ({ toggleState }) => {
  // const [tabstate, settabstate] = useState("CreativeTemplates");

  // const getTab = (e) => {
  //   settabstate(e.target.dataset.tab);
  // };

  return (
    <div
      data-tab="resources"
      className={toggleState === "resources" ? "active" : null}
    >
      <div className="flex fw-w ai-s jc-sb">
        <div className="colw-25">
          <ul className="reportTabs">
            <li
              data-tab="CreativeTemplates"
              className="active"
              // className={tabstate === "CreativeTemplates" ? "active" : ""}
              // onClick={(e) => getTab(e)}
            >
              <div>Creative Templates</div>
            </li>
            <li
            // data-tab="DownloadStarterKit"
            // className={tabstate === "DownloadStarterKit" ? "active" : ""}
            // onClick={(e) => getTab(e)}
            >
              <a
                href="https://brightline.app.box.com/s/xfrjfntzv1iv75h652rfv2je3r1mat61"
                rel="noreferrer"
                target="_blank"
              >
                Starter Kit
              </a>
            </li>
            <li
            // data-tab="ContactBrightLink"
            // className={tabstate === "ContactBrightLink" ? "active" : ""}
            // onClick={(e) => getTab(e)}
            >
              <a href="mailto:support@brightline.tv">Contact BrightLink</a>
            </li>
          </ul>
        </div>
        <div className="colw-70">
          <ul className="reportCont">
            <li
              data-tab="CreativeTemplates"
              // className={tabstate === "CreativeTemplates" ? "active" : ""}
              className="active"
            >
              <div className="flex fw-w txt-cntr">
                <div className="colw-33 p-25">
                  <a href="https://cdn-media.brightline.tv/brightlink-resources/psd-scripts/BrightLine_PSD_ISSO.zip">
                    <img src={download_icon} className="colw-100" alt="mac" />
                    <h5>In-Stream Overlay</h5>
                  </a>
                </div>

                <div className="colw-33 p-25">
                  <a href="https://cdn-media.brightline.tv/brightlink-resources/psd-scripts/BrightLine_PSD_Galleries.zip">
                    <img src={download_icon} className="colw-100" alt="mac" />
                    <h5>Galleries</h5>
                  </a>
                </div>
                {/* <div className="colw-33 p-25">
                  <a href="http://brightline.tv">
                    <img
                      src="assets/img/imac.png"
                      className="colw-100"
                      alt="mac"
                    />
                    <h5>Video Gallery</h5>
                  </a>
                </div> */}
              </div>
            </li>

            {/* <li
              data-tab="DownloadStarterKit"
              className={tabstate === "DownloadStarterKit" ? "active" : ""}
            >
              <h1 className="main-col">
                <strong>Starter Kit</strong>
              </h1>
              <div className="txt-edit mt-15">Starter Kit</div>
            </li> */}

            {/* <li
              data-tab="ContactBrightLink"
              className={tabstate === "ContactBrightLink" ? "active" : ""}
            >
              <h1 className="main-col">
                <strong>Contact BrightLink</strong>
              </h1>
              <div className="txt-edit mt-15">Contact BrightLink</div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResourcesTab;
