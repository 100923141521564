import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const LandingPage = () => {
  let history = useHistory(false);

  if (sessionStorage.getItem("isAuth") !== "true") {
    history.push("/");
  }
  return (
    <section className="flex fw fh jc-c ai-s fw-w">
      <div className="colw-50">
        <Link to="/Dashboard">
          <div className="flex fw fh jc-c ai-c main-bg white-col">
            <h1>Create a new Ad</h1>
          </div>
        </Link>
      </div>
      <div className="colw-50">
        <div className="flex fd-c fw fh jc-c ai-c secondary-bg white-col txt-l">
          <h1>
            View / Edit <br /> previous Ads
          </h1>
          <div>coming soon</div>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
