import React, { useState, useEffect } from "react";

const Campaigns = ({
  brandID,
  setCampaignID,
  setCampaignName,
  campaignName,
  setCreativeName,
  fw_counter,
  setfw_counter,
  setToggleState,
  reset_BL_Recipe,
  currentSelect,
  setCurrentSelect,
  setgenVAST,
}) => {
  const [posts, setPosts] = useState([]);
  const [isCampaignChanged, setisCampaignChanged] = useState(false);
  const CampaignsUrl = `${process.env.REACT_APP_API_BASE_ROUTE}campaigns/brand/${brandID}`;
  let newCampaign;

  useEffect(() => {
    let isMounted = true;

    if (sessionStorage.getItem("isAuth") === "true") {
      let req = new Request(CampaignsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
        },
        mode: "cors",
      });

      fetch(req)
        .then((res) => {
          return res.json();
        })
        .then((jsonData) => {
          if (jsonData.length === 0) {
            setPosts([]);
          } else {
            if (isMounted) setPosts(jsonData);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [CampaignsUrl, newCampaign, isCampaignChanged]);

  useEffect(() => {
    const isActive = document.getElementById("campaignSelect").classList.contains("active");
    if (isActive && currentSelect !== "campaignSelect") {
      document.getElementById("campaignSelect").classList.remove("active");
    }

  }, [currentSelect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    newCampaign = { campaignName };
    fetch(CampaignsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
      },
      body: JSON.stringify(newCampaign),
    })
      .then((res) => res.json())
      .then((res) => {
        setCampaignName(res.name);
        setCampaignID(res.id);
        setisCampaignChanged(true);
        setfw_counter(2);
        setTimeout(() => {
          document.getElementById(res.id).checked = true;
        }, 500);
      });
  };

  const openFrame = () => {
    document
      .querySelector('.addNewForm[data-form="Campaign"]')
      .classList.add("show");
  };

  const closeFrame = () => {
    document
      .querySelector('.addNewForm[data-form="Campaign"]')
      .classList.remove("show");
  };

  const showDDM = () => {
    if(document.getElementById("campaignSelect").children.length === 0){
      document.getElementById("campaignSelect").classList.remove("active");
      openFrame();
    } else{
      if (!document.getElementById("campaignSelect").classList.contains("active")) {
        setCurrentSelect("campaignSelect");
      }
      document.getElementById("campaignSelect").classList.toggle("active");
    }
  };

  const hideDDM = (e) => {
    if(e.target.id !== 'NA'){
      setCampaignName(e.target.value);
      setCampaignID(e.target.id);
      setCreativeName("");
      setfw_counter(2);
      setToggleState("upload")
      reset_BL_Recipe()
      setgenVAST("")
    }
    document.getElementById("campaignSelect").classList.remove("active");
  };

  const fixName = (el) => {
    const newName = el.replace(/[ `!@#$ %^&*()_+\-=[\]{};':"\\|,.<>/? ~]/g, '_');
    setCampaignName(newName)
  }
  return (
    <div className={`colw-100 valField ${fw_counter > 0 ? "" : "disabled"}`}>
      <h4 className="mb-5">
        <strong>Campaigns</strong>
      </h4>
      <div className="flex">
        <div className="fg-3">
          <div className="rel-sect">
            <span className="selectlike" onClick={showDDM}>
              {campaignName === "" ? "Choose a  Campaign" : campaignName}
            </span>
            <div className="ddm" id="campaignSelect">
              {posts.map((post) => (
                <div key={post.id}>
                  <input
                    type="radio"
                    name="campaignSelect"
                    value={post.name}
                    id={post.id}
                    onClick={hideDDM}
                  />
                  <label htmlFor={post.id}>{post.name}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pl-15">
          <div className="addNew" onClick={openFrame}>
            +
          </div>
          <div className="addNewForm" data-form="Campaign">
            <div className="rel-sect">
              <div className="closeForm" onClick={closeFrame}></div>
              <form onSubmit={handleSubmit}>
                <div className="row" data-spacing="5">
                  <div className="colw-100">
                    <h5>Add a new Campaign</h5>
                  </div>
                  <div className="colw-100">
                    <input
                      type="text"
                      id="addCampaign"
                      placeholder="Campaign Name"
                      className="br-5"
                      onChange={(e) => fixName(e.target.value)}
                    />
                  </div>
                  <div className="colw-100">
                    <input
                      type="submit"
                      value="submit"
                      className="br-5"
                      onClick={closeFrame}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
