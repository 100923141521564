import React from "react";
import TabNav from "./TabNav";
import TabContent from "./TabContent";
import PreviewAD from "../views/PreviewAD";

const Interface = ({
  brandID,
  campaignID,
  creativeID,
  brandName,
  campaignName,
  creativeName,
  setnotifyMsg,
  partnerName,
  partnerID,
  getCreative,
  setgetCreative,
  getpreviewVideo,
  setgetpreviewVideo,
  BL_videoStatus,
  setBL_videoStatus,
  genVAST,
  setgenVAST,
  videoJobID,
  setVideoJobID,
  videoProgress,
  setvideoProgress,
  prevAd,
  setprevAd,
  recipeID,
  setrecipeID,
  fetchingRecipe,
  setfetchingRecipe,
  hasRecipe,
  sethasRecipe,
  isloadAD,
  setisloadAD,
  fw_counter,
  setfw_counter,
  toggleState,
  setToggleState,
  spotvid,
  setspotvid,
  bground,
  setbground,
  fground,
  setfground,
  xpos,
  setxpos,
  ypos,
  setypos,
  svwidth,
  setsvwidth,
  videoSize,
  setvideoSize,
  assetPackage,
  setassetPackage,
  formState,
  setFormState,
  prevVid,
  setPrevVid,
  prevbg,
  setPrevbg,
  prevfg,
  setPrevfg,
  assetsArray,
  setAssetsArray,
  uploadedAssetArr,
  setuploadedAssetArr,
  assetsLoading,
  setassetsLoading,
  adGenerating,
  setadGenerating,
  assetsObj,
  setassetsObj,
  fillRecipe,
  setfillRecipe,
  reset_BL_Recipe,
}) => {

  const renderAD = () => {
    return <PreviewAD
        getCreative={getCreative}
        toggleState={toggleState}
        setToggleState={setToggleState}
        getpreviewVideo={getpreviewVideo}
        videoJobID={videoJobID}
        videoProgress={videoProgress}
        setvideoProgress={setvideoProgress}
        BL_videoStatus={BL_videoStatus}
        setBL_videoStatus={setBL_videoStatus}
        prevAd={prevAd}
        setprevAd={setprevAd}
        fetchingRecipe={fetchingRecipe}
        isloadAD={isloadAD}
        setisloadAD={setisloadAD}
        hasRecipe={hasRecipe}
        recipeID={recipeID}
        fw_counter={fw_counter}
        setfw_counter={setfw_counter}
        setnotifyMsg={setnotifyMsg}
        reset_BL_Recipe={reset_BL_Recipe}
      />
  }

  return (
    <div className="colw-80 res-s-100 areaView">
      <TabNav
        toggleState={toggleState}
        setToggleState={setToggleState}
        hasRecipe={hasRecipe}
        setprevAd={setprevAd}
        setisloadAD={setisloadAD}
      />
      <TabContent
        brandID={brandID}
        campaignID={campaignID}
        creativeID={creativeID}
        brandName={brandName}
        campaignName={campaignName}
        creativeName={creativeName}
        setnotifyMsg={setnotifyMsg}
        partnerName={partnerName}
        partnerID={partnerID}
        getCreative={getCreative}
        setgetCreative={setgetCreative}
        toggleState={toggleState}
        getpreviewVideo={getpreviewVideo}
        setgetpreviewVideo={setgetpreviewVideo}
        genVAST={genVAST}
        setgenVAST={setgenVAST}
        setVideoJobID={setVideoJobID}
        setvideoProgress={setvideoProgress}
        setprevAd={setprevAd}
        recipeID={recipeID}
        setrecipeID={setrecipeID}
        setfetchingRecipe={setfetchingRecipe}
        hasRecipe={hasRecipe}
        sethasRecipe={sethasRecipe}
        isloadAD={isloadAD}
        setisloadAD={setisloadAD}
        fw_counter={fw_counter}
        setfw_counter={setfw_counter}
        spotvid={spotvid}
        setspotvid={setspotvid}
        bground={bground}
        setbground={setbground}
        fground={fground}
        setfground={setfground}
        xpos={xpos}
        setxpos={setxpos}
        ypos={ypos}
        setypos={setypos}
        svwidth={svwidth}
        setsvwidth={setsvwidth}
        videoSize={videoSize}
        setvideoSize={setvideoSize}
        assetPackage={assetPackage}
        setassetPackage={setassetPackage}
        formState={formState}
        setFormState={setFormState}
        prevVid={prevVid}
        setPrevVid={setPrevVid}
        prevbg={prevbg}
        setPrevbg={setPrevbg}
        prevfg={prevfg}
        setPrevfg={setPrevfg}
        assetsArray={assetsArray}
        setAssetsArray={setAssetsArray}
        uploadedAssetArr={uploadedAssetArr}
        setuploadedAssetArr={setuploadedAssetArr}
        assetsLoading={assetsLoading}
        setassetsLoading={setassetsLoading}
        adGenerating={adGenerating}
        setadGenerating={setadGenerating}
        assetsObj={assetsObj}
        setassetsObj={setassetsObj}
        fillRecipe={fillRecipe}
        setfillRecipe={setfillRecipe}
        reset_BL_Recipe={reset_BL_Recipe}
      />
      {isloadAD === true ? renderAD() : null}
      
    </div>
  );
};

export default Interface;
